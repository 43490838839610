import React, { useEffect } from 'react';
import './App.css';

const loadScript = (src, async, innerHTML) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = async;
  if (innerHTML) {
    script.innerHTML = innerHTML;
  }
  document.body.appendChild(script);
};

const App = () => {

  useEffect(() => {
    loadScript('https://www.googletagmanager.com/gtag/js?id=G-061XSH4LHV', true);
    loadScript('', false, `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-061XSH4LHV');
    `);
    loadScript('https://platform.twitter.com/widgets.js', true);
  
    const initializeJupiter = () => {
      if (window.Jupiter) {
        window.Jupiter.init({
          displayMode: "integrated",
          integratedTargetId: "integrated-terminal",
          endpoint: "https://solana-mainnet.g.alchemy.com/v2/v3UKYVGex_cedU96ecIyScZ8W8u61-4j",
          formProps: {
            fixedInputMint: true,
            fixedOutputMint: true,
            initialInputMint: 'So11111111111111111111111111111111111111112',
            initialOutputMint: "BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL",
          },
        });
      }
    };
    
  
    if (window.Jupiter) {
      initializeJupiter();
    } else {
      window.addEventListener('jupiterReady', initializeJupiter);
    }
  
    return () => {
      window.removeEventListener('jupiterReady', initializeJupiter);
    };
  }, []);
  

  const clouds = [
    { className: 'cloud1', src: '/images/cloud.svg', alt: 'Cloud 1' },
    { className: 'cloud2', src: '/images/cloud1.svg', alt: 'Cloud 2' },
    { className: 'cloud3', src: '/images/cloud4.svg', alt: 'Cloud 3' },
    { className: 'cloud4', src: '/images/cloud3.svg', alt: 'Cloud 4' },
  ];

  const contentClouds = Array.from({ length: 6 }, (_, i) => ({
    className: `contentCloud contentCloud${i + 1}`,
    src: '/images/contentcloud.svg',
    alt: 'content cloud',
  }));

  return (
    <div>
      <div className="background">
        {clouds.map(cloud => (
          <img key={cloud.className} className={cloud.className} src={cloud.src} alt={cloud.alt} />
        ))}
      </div>

      <div className="container">
        <header className="header">
          <img className="moni_title" src="/images/moni_title.svg" alt="Moni Title" />
          <a href="https://raydium.io/swap/?outputMint=BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL&inputMint=sol" className="sub_title_link moni_pointer">
            <h1 className="sub_title no_margin">Buy Now</h1>
          </a>
          <div className="icon_box">
            {[
              { href: 'https://t.me/moni_today', src: '/images/telegram.svg', alt: 'Telegram' },
              { href: 'https://x.com/MonionSol', src: '/images/twitter.svg', alt: 'Twitter' },
              { href: 'https://linktr.ee/MonionSol', src: '/images/linktree.svg', alt: 'Linktree' },
              { href: 'https://usdmoni.gitbook.io/moni-on-solana/', src: '/images/gitbook.svg', alt: 'Gitbook' },
            ].map(icon => (
              <a key={icon.href} href={icon.href} className="moni_pointer">
                <img className="icon" src={icon.src} alt={icon.alt} />
              </a>
            ))}
          </div>
          <div className="moni_container">
            <div className="moni_text">
              CA:BVpWzKs6eDxw<br />
              GqNdsX43ri4uYoK<br />
              U7xTqxN7B<br />
              aVJvuSJL
            </div>
            <img className="moni" src="/images/moni.svg" alt="Moni" />
          </div>

          <nav className="menu">
            {[
              { href: '#about', src: '/images/about.svg', alt: 'About' },
              { href: '#token_info', src: '/images/token_info.svg', alt: 'Token Info' },
              { href: '#game', src: '/images/game_icon.svg', alt: 'Game' },
              { href: '#meme', src: '/images/meme.svg', alt: 'Meme' },
            ].map(menuItem => (
              <a key={menuItem.href} className="moni_pointer" href={menuItem.href}>
                <img src={menuItem.src} alt={menuItem.alt} />
              </a>
            ))}
          </nav>
        </header>

        <section className="content">
          {contentClouds.map(cloud => (
            <img key={cloud.className} className={cloud.className} src={cloud.src} alt={cloud.alt} />
          ))}

          <div className="section about" id="about">
            <img src="/images/about.png" className="about_bg_web" alt="About Background" />
            <img src="/images/about_bg_mobile.png" className="about_bg_mobile" alt="About Background Mobile" />
            <video controls autoPlay onLoadStart={(e) => (e.target.volume = 0.3)} className="moni_video">
              <source src="/images/MONI.mp4" type="video/mp4" />
            </video>
          </div>

          <div id="token_info" className="section token_info">
            <h2 className="token_title">Token Info</h2>
            <div className="moni_teach_web">
              <img src="/images/moni_teach.png" alt="Token Chart" style={{ zIndex: 20 }} />
              <div className="moni_teach_content">
                <p>Total Supply :<br />
                  1,000,000,000 $Moni<br />
                  OWNERSHIP RENOUNCED<br />
                  LP : 100% (Burnt)<br />
                  No-TAX
                </p>
                <h2 className="moni_pointer learn_more">Learn More</h2>
              </div>
            </div>
            <div className="moni_teach_mobile">
              <img src="/images/moni_teach_mobile.png" alt="Token Chart" />
              <div className="moni_teach_content">
                <p>Total Supply :<br />
                  1,000,000,000 $MONI<br />
                  OWNERSHIP RENOUNCED<br />
                  LP : 100% (Burnt)<br />
                  No-TAX
                </p>
                <h2 className="moni_pointer learn_more">Learn More</h2>
              </div>
            </div>
          </div>

          <div id="network" className="section network">
            <div className="network_div_web">
              <img className="moni_phone" src="/images/network.png" alt="Network" />
              <div className="network_item">
                <div className="network_item_row">
                  {[
                    { href: 'https://dexscreener.com/solana/7k8beapekporustadxyufwbu1j5md9hhmzzzim7hovzm', src: '/images/dexScreener.jpeg', alt: 'dexScreener' },
                    { href: 'https://www.dextools.io/app/en/solana/pair-explorer/7k8BeapEkPorUstADxyUfwBu1j5Md9hHmzZZim7hovzm', src: '/images/dexTools.png', alt: 'dexTools' },
                    { href: 'https://www.mexc.com/exchange/MONI_USDT', src: '/images/mexc.jpg', alt: 'mexc' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content moni_pointer" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  {[
                    { href: 'https://www.lbank.com/trade/moni_usdt', src: '/images/lBank.jpg', alt: 'lBank' },
                    { href: 'https://www.coingecko.com/en/coins/moni', src: '/images/coingecko.png', alt: 'coingecko' },
                    { href: 'https://jup.ag/swap/SOL-BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL', src: '/images/jup.png', alt: 'jup' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content moni_pointer" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  {[
                    { href: 'https://www.okx.com/web3/dex-market/detail/501/BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL', src: '/images/okxDex.png', alt: 'okx_dex' },
                    { href: 'https://app.meteora.ag/dlmm/AkBQfxhwmEWqQxQCHHzZcWifxjaUBXS8ucXqyX8RshrB', src: '/images/meteora.jpg', alt: 'meteora' },
                    { href: 'https://birdeye.so/token/BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL?chain=solana', src: '/images/birdeye.png', alt: 'birdeye' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content moni_pointer" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  <a className="network_item_content moni_pointer" href="https://photon-sol.tinyastro.io/en/lp/7k8BeapEkPorUstADxyUfwBu1j5Md9hHmzZZim7hovzm?handle=4705382ce85145e2674da" target="_blank" rel="noopener noreferrer">
                    <img className="network_item_content" src="/images/photon.jpeg" alt="photon" />
                  </a>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 className="network_title">Net</h2>
                <h2 className="network_title">work</h2>
              </div>
            </div>
            <div className="network_div_mobile">
              <h2 className="network_title">Network</h2>
              <img className="moni_phone" src="/images/network_mobile.svg" alt="Network" />
              <div className="network_item">
                <div className="network_item_row" style={{ marginTop: '0.4rem' }}>
                  {[
                    { href: 'https://dexscreener.com/solana/7k8beapekporustadxyufwbu1j5md9hhmzzzim7hovzm', src: '/images/dexScreener.jpeg', alt: 'dexScreener' },
                    { href: 'https://www.dextools.io/app/en/solana/pair-explorer/7k8BeapEkPorUstADxyUfwBu1j5Md9hHmzZZim7hovzm?t=1719409901919', src: '/images/dexTools.png', alt: 'dexTools' },
                    { href: 'https://www.mexc.com/exchange/MONI_USDT', src: '/images/mexc.jpg', alt: 'mexc' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  {[
                    { href: 'https://www.lbank.com/trade/moni_usdt', src: '/images/lBank.jpg', alt: 'lBank' },
                    { href: 'https://www.coingecko.com/en/coins/moni', src: '/images/coingecko.png', alt: 'coingecko' },
                    { href: 'https://jup.ag/swap/SOL-BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL', src: '/images/jup.png', alt: 'jup' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content moni_pointer" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  {[
                    { href: 'https://www.okx.com/web3/dex-market/detail/501/BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL', src: '/images/okxDex.png', alt: 'okx_dex' },
                    { href: 'https://app.meteora.ag/dlmm/AkBQfxhwmEWqQxQCHHzZcWifxjaUBXS8ucXqyX8RshrB', src: '/images/meteora.jpg', alt: 'meteora' },
                    { href: 'https://birdeye.so/token/BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL?chain=solana', src: '/images/birdeye.png', alt: 'birdeye' },
                  ].map(network => (
                    <a key={network.href} className="network_item_content moni_pointer" href={network.href} target="_blank" rel="noopener noreferrer">
                      <img src={network.src} alt={network.alt} />
                    </a>
                  ))}
                </div>
                <div className="network_item_row">
                  <a className="network_item_content moni_pointer" href="https://photon-sol.tinyastro.io/en/lp/7k8BeapEkPorUstADxyUfwBu1j5Md9hHmzZZim7hovzm?handle=4705382ce85145e2674da" target="_blank" rel="noopener noreferrer">
                    <img className="network_item_content" src="/images/photon.jpeg" alt="photon" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="chart" className="section chart">
            <img src="/images/chart.png" alt="chart" />
            <div className="chart_div">
              <div id="birdeye_chart" className="section birdeye_chart">
                <iframe className="chart_api"
                  src="https://birdeye.so/tv-widget/BVpWzKs6eDxwGqNdsX43ri4uYoKU7xTqxN7BaVJvuSJL?chain=solana&viewMode=pair&chartInterval=1D&chartType=CANDLE&chartTimezone=Asia%2FKorea&chartLeftToolbar=show&theme=dark"
                  frameBorder="0"
                  allowFullScreen
                  title="Chart"
                ></iframe>
              </div>
            </div>
          </div>

          <div id="partners" className="section partners">
            <h1>Partners</h1>
            <div className="partners_div">
              {[
                { href: 'https://gotbit.io/', src: '/images/gotbit.png', alt: 'gotbit' },
                { href: 'https://www.bonkbot.io/', src: '/images/bonkbot.png', alt: 'bonkbot', style: { marginLeft: '3rem' } },
              ].map(partner => (
                <a key={partner.href} className="moni_pointer" href={partner.href}>
                  <img className="partners_img" src={partner.src} alt={partner.alt} style={partner.style} />
                </a>
              ))}
            </div>
          </div>

          <div id="game" className="section game">
            <h2 className="game_title">Moni Game</h2>
            <a href="https://ninanuno.itch.io/moni-survivor">
              <img className="moni_pointer" src="/images/game_image.png" alt="game" />
            </a>
          </div>

          <div id="meme" className="section meme">
            <h2 className="meme_title">Meme</h2>
            <div className="meme_grid">
              {[
                { src: '/images/meme1.svg', alt: 'Meme 1' },
                { src: '/images/meme2.svg', alt: 'Meme 4' },
                { src: '/images/swim.jpg', alt: 'Meme 3' },
                { src: '/images/moneybath.jpg', alt: 'Meme 4' },
                { src: '/images/birdsky.png', alt: 'Meme 4' },
                { src: '/images/fuck.jpg', alt: 'Meme 2' },
                { src: '/images/food.png', alt: 'Meme 2' },
                { src: '/images/flly.png', alt: 'Meme 3' },
                { src: '/images/car.png', alt: 'Meme 1' },
                { src: '/images/cut.jpg', alt: 'Meme 1' },
                { src: '/images/angry.jpg', alt: 'Meme 2' },
                { src: '/images/strong.jpg', alt: 'Meme 3' },
              ].map(meme => (
                <img key={meme.src} src={meme.src} alt={meme.alt} />
              ))}
            </div>
          </div>
        </section>
      </div>

      <div id="integrated-terminal"></div>

      <footer className="footer">
        <div className="footer_div">
          <p className="footer_txt"><a className="moni_pointer" href="mailto:Moni@moni.today">CONTACT - Moni@moni.today</a></p>
          <p className="footer_txt">&nbsp; Risks in meme coin investment, losses are the investor's responsibility.&nbsp;</p>
          <p className="footer_txt"><a className="moni_pointer" href="mailto:Moni@moni.today">CONTACT - Moni@moni.today</a></p>
          <p className="footer_txt">&nbsp; Risks in meme coin investment, losses are the investor's responsibility.&nbsp;</p>
        </div>
      </footer>

    </div>
  );
};

export default App;
